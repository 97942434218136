<template>
  <div class="SportsManagement">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <div class="btn">
          <span style="height: 40px; line-height: 40px">年度：</span>
          <el-input
            style="padding: 3px 6px; margin-right: 20px; width: 200px"
            v-model="year"
          ></el-input>
          <span style="height: 40px; line-height: 40px">赛事名称：</span>
          <el-input
            style="padding: 3px 6px; margin-right: 20px; width: 200px"
            v-model="competitionName"
          ></el-input>
          <span class="whether">是否可见：</span>
          <el-select v-model="isShow" placeholder="请选择" class="select">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-button
            style="height: 40px"
            type="primary"
            plain
            @click="getTableData"
            >搜索</el-button
          >
          <el-button
            style="height: 40px"
            type="primary"
            plain
            @click="handleAddGame"
            >添加赛事</el-button
          >
        </div>
      </div>

      <div class="main-card">
        <el-table
          :data="tableData"
          style="width: 100%"
          height="550"
          :header-cell-style="{ textAlign: 'center' }"
          :cell-style="{ textAlign: 'center' }"
        >
          <el-table-column label="赛事ID">
            <template slot-scope="scope">
              <span>{{ scope.row.id }}</span>
            </template>
          </el-table-column>
          <el-table-column label="赛事名称">
            <template slot-scope="scope">
              <span>{{ scope.row.name }}</span>
            </template>
          </el-table-column>
          <el-table-column label="比赛日期">
            <template slot-scope="scope">
              <span>{{
                $moment(scope.row.startTime).format("YYYY-MM-DD")
              }}</span>
              ~<span>{{
                $moment(scope.row.endTime).format("YYYY-MM-DD")
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="报名截止">
            <template slot-scope="scope">
              <span>{{
                $moment(scope.row.applyEndTime).format("YYYY-MM-DD HH:mm")
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="付款截止">
            <template slot-scope="scope">
              <span>{{
                $moment(scope.row.payEndTime).format("YYYY-MM-DD HH:mm")
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="组别数目" width="100">
            <template slot-scope="scope">
              <span>{{ scope.row.groupCount }}</span>
            </template>
          </el-table-column>
          <el-table-column label="是否可见" width="100">
            <template slot-scope="scope">
              <el-switch
                class="switch"
                style="display: block; width: 52px"
                v-model="scope.row.isShow"
                :active-value="1"
                :inactive-value="0"
                active-color="#13ce66"
                inactive-color="#ff4949"
                active-text="on"
                inactive-text="off"
                @change="changeStatu(scope.row.id, scope.row.isShow)"
              >
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="450">
            <template slot-scope="scope">
              <el-button
                size="mini"
                @click="handleDetail(scope.$index, scope.row)"
                >详情</el-button
              >

              <el-button
                size="mini"
                @click="handleTicket(scope.$index, scope.row)"
                >门票</el-button
              >

              <el-button
                size="mini"
                @click="handleRefund(scope.$index, scope.row)"
                >退款比例设置</el-button
              >
              <el-button
                size="mini"
                @click="handleHotel(scope.$index, scope.row)"
                >酒店管理</el-button
              >
              <el-dropdown trigger="click">
                <el-button
                  size="mini"
                  class="el-dropdown-link"
                  style="margin-left: 10px"
                >
                  更多<i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item>
                    <el-button
                      size="mini"
                      @click="handleEdit(scope.$index, scope.row)"
                      >编辑</el-button
                    >
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <el-button
                      size="mini"
                      type="danger"
                      @click="handleDelete(scope.row.id)"
                      >删除</el-button
                    >
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <el-button
                      size="mini"
                      @click="handleExport(scope.$index, scope.row,0)"
                      >导出到计分</el-button>
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <el-button
                      size="mini"
                      @click="handleExport(scope.$index, scope.row,1)"
                      >导出到计分(全部)</el-button>
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <el-button
                      size="mini"
                      @click="handleDiscount(scope.$index, scope.row)"
                      >折扣</el-button
                    ></el-dropdown-item
                  >
                  <el-dropdown-item :disabled="scope.row.status == 5">
                    <el-button size="mini" @click="offRace(scope.row.id)"
                      >取消赛事</el-button
                    ></el-dropdown-item
                  >
                  <el-dropdown-item v-if="scope.row.status == 5">
                    <el-button size="mini" @click="clearApply(scope.row.id)"
                      >清理未支付报名
                    </el-button></el-dropdown-item
                  >
                  <el-dropdown-item v-if="scope.row.status == 5">
                    <el-button
                      size="mini"
                      @click="refundMoney(scope.$index, scope.row)"
                      >退还费用
                    </el-button></el-dropdown-item
                  >
                  <el-dropdown-item>
                    <el-button
                            size="mini"
                            @click="handleExportOrderInfo(scope.$index, scope.row)"
                    >导出订单明细</el-button>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="page-card">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalCount"
        >
        </el-pagination>
      </div>
    </el-card>

    <el-dialog
      title="添加赛事"
      :visible.sync="dialogFormVisible"
      @close="closeDialog"
      class="competition"
      :close-on-click-modal="false"
    >
      <el-form ref="formAdd" :model="form" label-width="100px" :rules="rules">
        <div style="display: flex">
          <el-form-item label="赛事分级:" prop="level">
            <el-select
              v-model="form.level"
              placeholder="请选择赛事分级"
              style="width: 202px"
            >
              <el-option
                v-for="item in levelList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="分级赛事:" prop="type">
            <el-select
              v-model="form.type"
              placeholder="请选择分级赛事"
              style="width: 202px"
            >
              <el-option label="全国精英赛" value="全国精英赛"></el-option>
              <el-option label="年度总决赛" value="年度总决赛"></el-option>
              <el-option label="世界杯公开赛" value="世界杯公开赛"></el-option>
              <el-option label="全国锦标赛" value="全国锦标赛"></el-option>
              <el-option label="院校杯公开赛" value="院校杯公开赛"></el-option>
              <el-option label="亚洲巡回赛" value="亚洲巡回赛"></el-option>
              <el-option label="中国杯巡回赛" value="中国杯巡回赛"></el-option>
              <el-option label="青少年锦标赛" value="青少年锦标赛"></el-option>
              <el-option label="全国城市联赛" value="全国城市联赛"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="发票类型:" prop="invoiceType">
            <el-select
              v-model="form.invoiceType"
              placeholder="请选择发票类型"
              style="width: 202px"
            >
              <el-option
                v-for="item in invoiceList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </div>
        <div style="display: flex">
          <el-form-item label="比赛城市:" prop="city">
            <el-input v-model="form.city"></el-input>
          </el-form-item>
          <el-form-item label="有无奖金:" prop="isNeedBonus">
            <el-select
              v-model="form.isNeedBonus"
              placeholder="请选择是否需要奖金"
              style="width: 202px"
            >
              <el-option
                v-for="item in whether"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="是否预告:" prop="isAdvance">
            <el-select
              v-model="form.isAdvance"
              placeholder="是否为预告阶段"
              style="width: 202px"
            >
              <el-option
                v-for="item in whether"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </div>
        <el-form-item label="发票联系人:" prop="invoiceRemark">
          <el-input v-model="form.invoiceRemark"></el-input>
        </el-form-item>
        <el-form-item label="赛事名称:" prop="name">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="场馆名称:" prop="buildingName">
          <el-input v-model="form.buildingName"></el-input>
        </el-form-item>
        <el-form-item label="场馆地址:" prop="address">
          <el-input v-model="form.address"></el-input>
        </el-form-item>
        <el-form-item label="赛事地址:" prop="areaName">
          <el-input v-model="form.areaName"></el-input>
        </el-form-item>
        <el-form-item label="报到地址:" prop="registerAddress">
          <el-input v-model="form.registerAddress"></el-input>
        </el-form-item>
        <el-form-item label="通知链接:">
          <el-input v-model="form.noticeUrl"></el-input>
        </el-form-item>

        <div style="display: flex">
          <el-form-item label="比赛日期" prop="startTime">
            <el-date-picker
              type="datetime"
              placeholder="选择开始日期"
              v-model="form.startTime"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd 00:00:00"
              style="width: 100%"
            ></el-date-picker>
          </el-form-item>
          <el-col :span="1" style="color: black; text-align: center">~</el-col>
          <el-form-item prop="endTime" label-width="0px">
            <el-date-picker
              type="datetime"
              placeholder="选择结束日期"
              v-model="form.endTime"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd 00:00:00"
              style="width: 100%"
            ></el-date-picker>
          </el-form-item>
        </div>
        <div style="display: flex">
          <el-form-item label="报到日期" prop="registerStartTime">
            <el-date-picker
              type="datetime"
              placeholder="选择开始日期"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd 00:00:00"
              v-model="form.registerStartTime"
              style="width: 100%"
            ></el-date-picker>
          </el-form-item>
          <el-col :span="1" style="color: black; text-align: center">~</el-col>
          <el-form-item prop="registerEndTime" label-width="0px">
            <el-date-picker
              type="datetime"
              placeholder="选择结束日期"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd 00:00:00"
              v-model="form.registerEndTime"
              style="width: 100%"
            ></el-date-picker>
          </el-form-item>
        </div>
        <el-form-item
          label="报名开始时间"
          prop="applyStartTime"
          label-width="120px"
        >
          <el-col :span="12">
            <el-date-picker
              type="datetime"
              placeholder="选择日期"
              format="yyyy-MM-dd HH:mm:ss"
              value-format="yyyy-MM-dd HH:mm:ss"
              v-model="form.applyStartTime"
              style="width: 100%"
            ></el-date-picker>
          </el-col>
        </el-form-item>
        <el-form-item
          label="报名截止时间"
          prop="applyEndTime"
          label-width="120px"
        >
          <el-col :span="12">
            <el-date-picker
              type="datetime"
              placeholder="选择日期"
              format="yyyy-MM-dd HH:mm:ss"
              value-format="yyyy-MM-dd HH:mm:ss"
              v-model="form.applyEndTime"
              style="width: 100%"
            ></el-date-picker>
          </el-col>
        </el-form-item>

        <el-form-item
          label="付款截止时间"
          prop="payEndTime"
          label-width="120px"
        >
          <el-col :span="12">
            <el-date-picker
              type="datetime"
              placeholder="选择日期"
              format="yyyy-MM-dd HH:mm:ss"
              value-format="yyyy-MM-dd HH:mm:ss"
              v-model="form.payEndTime"
              style="width: 100%"
            ></el-date-picker>
          </el-col>
        </el-form-item>

        <el-form-item label="参赛服务费折扣信息:">
          <el-form-item
            v-for="(domain, index) in form.discountSetting"
            :key="index"
            style="height: 50px"
            prop="startMoney"
          >
            <el-col :span="3">
              <el-input-number
                v-model="domain.startMoney"
                size="mini"
                placeholder="优惠开始金额"
                :precision="2"
              ></el-input-number>
            </el-col>
            <el-col
              :span="1"
              style="color: black; text-align: center; margin-left: 20px"
              >~</el-col
            >

            <el-col :span="3">
              <el-input-number
                v-model="domain.endMoney"
                size="mini"
                placeholder="优惠截止金额"
                :precision="2"
              ></el-input-number>
            </el-col>
            <el-col
              :span="3"
              style="color: black; text-align: center; margin-left: 20px"
              >折扣率：</el-col
            >
            <el-col :span="3">
              <el-input-number
                v-model="domain.disCountRate"
                size="mini"
                placeholder="折扣率"
                :precision="2"
              ></el-input-number>
            </el-col>
            <el-col
              :span="5"
              style="color: black; text-align: center; margin-left: 20px"
              >(如打85折,请填写0.85)</el-col
            >
            <!-- <el-input v-model="domain.value" placeholder="审批人"></el-input> -->
            <el-button @click.prevent="removeDomain(domain)">删除</el-button>
          </el-form-item>

          <el-button @click="addDomain" style="margin-top: 50px"
            >添加折扣</el-button
          >
        </el-form-item>
        <el-form-item label="入群二维码">
          <el-upload
            class="upload-demo"
            action=""
            :on-remove="onCodeRemove"
            :on-change="getCodeFile"
            :http-request="uploadJoinCodeImg"
            :limit="1"
            :on-exceed="handleCodeExceed"
            list-type="picture-card"
            :file-list="codeFileList"
            :auto-upload="false"
          >
            <i class="iconfont iconfont-competition">&#xe646;</i>
          </el-upload>
        </el-form-item>
        <el-form-item label="赛事封面图">
          <el-upload
            class="upload-demo"
            action=""
            :on-remove="onCompetitionRemove"
            :on-change="getCompetitionFile"
            :http-request="uploadCompetitionImg"
            :limit="1"
            :on-exceed="handleComExceed"
            list-type="picture-card"
            :file-list="competitionFileList"
            :auto-upload="false"
          >
            <i class="iconfont iconfont-competition">&#xe6e8;</i>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="pushGame">提交</el-button>
      </div>
    </el-dialog>

    <el-dialog
      title="编辑赛事"
      :visible.sync="dialogEditVisible"
      :close-on-click-modal="false"
      class="competition"
      @close="closeDialog"
    >
      <el-form
        ref="formEdit"
        :model="editForm"
        label-width="100px"
        :rules="rules"
      >
        <div style="display: flex">
          <el-form-item label="赛事分级:" prop="level">
            <el-select
              v-model="editForm.level"
              placeholder="请选择赛事分级"
              style="width: 202px"
            >
              <el-option
                v-for="item in levelList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="分级赛事:" prop="type">
            <el-select v-model="editForm.type" placeholder="请选择分级赛事">
              <el-option label="全国精英赛" value="全国精英赛"></el-option>
              <el-option label="年度总决赛" value="年度总决赛"></el-option>
              <el-option label="世界杯公开赛" value="世界杯公开赛"></el-option>
              <el-option label="全国锦标赛" value="全国锦标赛"></el-option>
              <el-option label="院校杯公开赛" value="院校杯公开赛"></el-option>
              <el-option label="亚洲巡回赛" value="亚洲巡回赛"></el-option>
              <el-option label="中国杯巡回赛" value="中国杯巡回赛"></el-option>
              <el-option label="青少年锦标赛" value="青少年锦标赛"></el-option>
              <el-option label="全国城市联赛" value="全国城市联赛"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="发票类型:" prop="invoiceType">
            <el-select
              v-model="editForm.invoiceType"
              placeholder="请选择发票类型"
              style="width: 202px"
            >
              <el-option
                v-for="item in invoiceList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </div>
        <div style="display: flex">
          <el-form-item label="比赛城市:" prop="city">
            <el-input v-model="editForm.city"></el-input>
          </el-form-item>
          <el-form-item label="有无奖金:" prop="isNeedBonus">
            <el-select
              v-model="editForm.isNeedBonus"
              placeholder="请选择是否需要奖金"
            >
              <el-option
                v-for="item in whether"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="是否预告:" prop="isAdvance">
            <el-select
              v-model="editForm.isAdvance"
              placeholder="是否为预告阶段"
              style="width: 202px"
            >
              <el-option
                v-for="item in whether"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </div>
        <el-form-item label="发票联系人:" prop="invoiceRemark">
          <el-input v-model="editForm.invoiceRemark"></el-input>
        </el-form-item>
        <el-form-item label="赛事名称:" prop="name">
          <el-input v-model="editForm.name"></el-input>
        </el-form-item>

        <el-form-item label="场馆名称:" prop="buildingName">
          <el-input v-model="editForm.buildingName"></el-input>
        </el-form-item>

        <el-form-item label="场馆地址:" prop="address">
          <el-input v-model="editForm.address"></el-input>
        </el-form-item>
        <el-form-item label="赛事地址:" prop="areaName">
          <el-input v-model="editForm.areaName"></el-input>
        </el-form-item>
        <el-form-item label="报到地址:" prop="registerAddress">
          <el-input v-model="editForm.registerAddress"></el-input>
        </el-form-item>
        <el-form-item label="通知链接:">
          <el-input v-model="editForm.noticeUrl"></el-input>
        </el-form-item>

        <div style="display: flex">
          <el-form-item label="比赛日期" prop="startTime">
            <el-date-picker
              type="datetime"
              placeholder="选择开始日期"
              v-model="editForm.startTime"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd HH:mm:ss"
              style="width: 100%"
            ></el-date-picker>
          </el-form-item>
          <el-col :span="1" style="color: black; text-align: center">~</el-col>
          <el-form-item prop="endTime" label-width="0px">
            <el-date-picker
              type="datetime"
              placeholder="选择结束日期"
              v-model="editForm.endTime"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd HH:mm:ss"
              style="width: 100%"
            ></el-date-picker>
          </el-form-item>
        </div>
        <div style="display: flex">
          <el-form-item label="报到日期" prop="registerStartTime">
            <el-date-picker
              type="datetime"
              placeholder="选择开始日期"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd HH:mm:ss"
              v-model="editForm.registerStartTime"
              style="width: 100%"
            ></el-date-picker>
          </el-form-item>
          <el-col :span="1" style="color: black; text-align: center">~</el-col>
          <el-form-item prop="registerEndTime" label-width="0px">
            <el-date-picker
              type="datetime"
              placeholder="选择结束日期"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd HH:mm:ss"
              v-model="editForm.registerEndTime"
              style="width: 100%"
            ></el-date-picker>
          </el-form-item>
        </div>
        <el-form-item
          label="报名开始时间"
          prop="applyStartTime"
          label-width="120px"
        >
          <el-col :span="12">
            <el-date-picker
              type="datetime"
              placeholder="选择日期"
              format="yyyy-MM-dd HH:mm:ss"
              value-format="yyyy-MM-dd HH:mm:ss"
              v-model="editForm.applyStartTime"
              style="width: 100%"
            ></el-date-picker>
          </el-col>
        </el-form-item>
        <el-form-item
          label="报名截止时间"
          prop="applyEndTime"
          label-width="120px"
        >
          <el-col :span="12">
            <el-date-picker
              type="datetime"
              placeholder="选择日期"
              format="yyyy-MM-dd HH:mm:ss"
              value-format="yyyy-MM-dd HH:mm:ss"
              v-model="editForm.applyEndTime"
              style="width: 100%"
            ></el-date-picker>
          </el-col>
        </el-form-item>

        <el-form-item
          label="付款截止时间"
          prop="payEndTime"
          label-width="120px"
        >
          <el-col :span="12">
            <el-date-picker
              type="datetime"
              placeholder="选择日期"
              format="yyyy-MM-dd HH:mm:ss"
              value-format="yyyy-MM-dd HH:mm:ss"
              v-model="editForm.payEndTime"
              style="width: 100%"
            ></el-date-picker>
          </el-col>
        </el-form-item>

        <el-form-item label="参赛服务费折扣信息:">
          <el-form-item
            v-for="(domain, index) in editForm.discountSetting"
            :key="index"
            style="height: 50px"
          >
            <el-col :span="3">
              <el-input
                v-model="domain.startMoney"
                size="mini"
                placeholder="优惠开始金额"
              ></el-input>
            </el-col>
            <el-col :span="1" style="color: black; text-align: center"
              >~</el-col
            >
            <el-col :span="3">
              <el-input
                v-model="domain.endMoney"
                size="mini"
                placeholder="优惠截止金额"
              ></el-input>
            </el-col>
            <el-col :span="3" style="color: black; text-align: center"
              >折扣率：</el-col
            >
            <el-col :span="3">
              <el-input
                v-model="domain.disCountRate"
                size="mini"
                placeholder="折扣率"
              ></el-input>
            </el-col>
            <el-col :span="5" style="color: black; text-align: center"
              >(如打85折,请填写0.85)</el-col
            >
            <!-- <el-input v-model="domain.value" placeholder="审批人"></el-input> -->
            <el-button @click.prevent="removeDomainEdit(domain)"
              >删除</el-button
            >
          </el-form-item>

          <el-button @click="addDomainEdit" style="margin-top: 20px"
            >添加折扣</el-button
          >
        </el-form-item>
        <el-form-item label="入群二维码">
          <el-upload
            class="upload-demo"
            action=""
            :on-remove="onCodeEditRemove"
            :on-change="getCodeEditFile"
            :http-request="uploadJoinCodeImg"
            :limit="1"
            :on-exceed="handleCodeExceed"
            list-type="picture-card"
            :file-list="codeFileList"
            :auto-upload="false"
          >
            <i class="iconfont iconfont-competition">&#xe646;</i>
          </el-upload>
        </el-form-item>

        <el-form-item label="赛事封面图">
          <el-upload
            class="upload-demo"
            action=""
            :on-remove="onCompetitionEditRemove"
            :on-change="getCompetitionEditFile"
            :http-request="uploadCompetitionImg"
            :limit="1"
            :on-exceed="handleComExceed"
            list-type="picture-card"
            :file-list="competitionFileList"
            :auto-upload="false"
          >
            <i class="iconfont iconfont-competition">&#xe6e8;</i>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="pushEdit">提交</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      competitionFileList: [],
      codeFileList: [],
      whether: [
        {
          value: 0,
          label: "否",
        },
        {
          value: 1,
          label: "是",
        },
      ],
      invoiceList: [
        {
          value: 0,
          label: "电子",
        },
        { value: 1, label: "纸质" },
      ],
      levelList: [
        {
          value: 1,
          label: "一级赛事",
        },
        {
          value: 2,
          label: "二级赛事",
        },
        {
          value: 3,
          label: "三级赛事",
        },
      ],
      options: [
        {
          value: "",
          label: "所有",
        },
        {
          value: 0,
          label: "不可见",
        },
        {
          value: 1,
          label: "可见",
        },
      ],
      isShow: "",
      tableData: [],
      year: "",
      competitionName: "",
      currentPage: 1,
      pageSize: 10,
      totalCount: 0,
      dialogFormVisible: false,
      dialogEditVisible: false,
      form: {
        scale: "",
        level: "",
        city: "",
        address: "",
        areaName: "",
        type: "",
        name: "",
        registerAddress: "",
        startTime: "",
        endTime: "",
        applyEndTime: "",
        payEndTime: "",
        registerStartTime: "",
        registerEndTime: "",
        discountSetting: [],
      },
      editForm: {},
      editFormPush: {},
      rules: {
        level: [{ required: true, message: "请选择赛事分级", trigger: "blur" }],
        type: [{ required: true, message: "请选择分级赛事", trigger: "blur" }],
        name: [{ required: true, message: "请输入赛事名称", trigger: "blur" }],
        city: [{ required: true, message: "请输入比赛城市", trigger: "blur" }],
        isNeedBonus: [
          { required: true, message: "请选择是否需要奖金", trigger: "blur" },
        ],
        isAdvance: [
          { required: true, message: "请选择是否为预告阶段", trigger: "blur" },
        ],
        buildingName: [
          { required: true, message: "请输入场馆名称", trigger: "blur" },
        ],
        address: [
          { required: true, message: "请输入场馆地址", trigger: "blur" },
        ],
        areaName: [
          { required: true, message: "请输入赛事地址", trigger: "blur" },
        ],
        registerAddress: [
          { required: true, message: "请输入报到地址", trigger: "blur" },
        ],
        // noticeUrl: [
        //   { required: true, message: "请输入通知链接", trigger: "blur" },
        // ],
        startTime: [
          { required: true, message: "请选择比赛开始日期", trigger: "blur" },
        ],
        endTime: [
          { required: true, message: "请选择比赛结束日期", trigger: "blur" },
        ],
        registerStartTime: [
          { required: true, message: "请选择报到开始日期", trigger: "blur" },
        ],
        registerEndTime: [
          { required: true, message: "请选择报到结束日期", trigger: "blur" },
        ],
        applyEndTime: [
          { required: true, message: "请选择报名截止时间", trigger: "blur" },
        ],
        applyStartTime: [
          {
            required: true,
            message: "请选择报名开始时间",
            trigger: "blur",
          },
        ],
        payEndTime: [
          { required: true, message: "请选择付款截止时间", trigger: "blur" },
        ],
        invoiceType: [
          { required: true, message: "请选择发票类型", trigger: "blur" },
        ],
        invoiceRemark: [
          { required: true, message: "请输入发票联系人", trigger: "blur" },
        ],
      },
      codePath: "",
      codeUrlList: [],
      codeFile: "",
      codeFileName: "",
      joinQrCode: [],
      competitionPath: "",
      competitionUrlList: [],
      competitionFile: "",
      competitionFileName: "",
      coverUrl: "",
      isUpload: false,
      isUpload2: true,
    };
  },
  created() {
    this.getUserInfo();
    this.getTableData();
  },
  methods: {
    //跳转折扣界面
    handleDiscount(index, row) {
      this.$router.push({
        name: "Discount",
        params: { back: row.name, id: row.id, name: "1", endTime: row.endTime },
      }); // 折扣界面
    },
    handleCodeExceed(files, fileList) {
      console.log(files, fileList);
      this.$message.warning(
        `当前限制选择1个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    handleComExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择1个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },

    async uploadJoinCodeImg() {
      try {
        const result = await this.$api.uploadCompetitionImg(
          `fileName=${this.codeFileName}`
        ); //获取上传配置信息
        console.log(result);
        this.codePath = result.showDomain; //保存配置信息
        // 2、设置上传配置
        let formData = new FormData();
        formData.append("authorization", result.authorization);
        formData.append("policy", result.policy);
        formData.append("file", this.codeFile);
        this.$http
          .post(result.commitPath, formData, {
            timeout: 3600000,
            onUploadProgress: (e) => {
              if (e.lengthComputable) {
                let rate = ((e.loaded * 100) / e.total).toFixed(2);
                if (rate < 100) {
                }
              }
            },
          })
          .then((res) => {
            this.codeUrlList.push(res.data.url); //获取单张图片的url后进行数组形式的存储
          });
      } catch (error) {
        console.log(error);
      }
    },
    getCodeFile(file, filelist) {
      this.codeFileName = file.name;
      this.codeFile = file.raw;
      this.uploadJoinCodeImg();
    },
    getCodeEditFile(file, fileList) {
      this.codeFileName = file.name;
      this.codeFile = file.raw;
      this.uploadJoinCodeImg();
    },
    onCodeRemove(file, fileList) {
      console.log(file, fileList);
      this.codeUrlList = [];
      if (fileList.length > 0) {
        fileList.forEach((item) => {
          this.codeFileName = item.name;
          this.codeFile = item.raw;
          this.uploadJoinCodeImg();
        });
      }
    },
    onCodeEditRemove(file, fileList) {
      console.log(file, fileList);
      for (let j = 0; j < fileList.length; j++) {
        if (fileList[j].name && fileList[j].raw) {
          this.codeFileName = fileList[j].name;
          this.codeFile = fileList[j].raw;
          this.uploadJoinCodeImg();
        }
      }

      if (this.editForm.joinQrCode == file.url) {
        this.editForm.joinQrCode = "";
        this.codeUrlList = [];
      }
    },
    async uploadCompetitionImg() {
      try {
        const result = await this.$api.uploadCompetitionImg(
          `fileName=${this.competitionFileName}`
        ); //获取上传配置信息
        console.log(result);
        this.competitionPath = result.showDomain; //保存配置信息
        // 2、设置上传配置
        let formData = new FormData();
        formData.append("authorization", result.authorization);
        formData.append("policy", result.policy);
        formData.append("file", this.competitionFile);
        this.$http
          .post(result.commitPath, formData, {
            timeout: 3600000,
            onUploadProgress: (e) => {
              if (e.lengthComputable) {
                let rate = ((e.loaded * 100) / e.total).toFixed(2);
                if (rate < 100) {
                }
              }
            },
          })
          .then((res) => {
            this.competitionUrlList.push(res.data.url); //获取单张图片的url后进行数组形式的存储
          });
      } catch (error) {
        console.log(error);
      }
    },
    getCompetitionFile(file, filelist) {
      this.competitionFileName = file.name;
      this.competitionFile = file.raw;
      if (filelist.length > 0) {
        this.isUpload = true;
      }
      this.uploadCompetitionImg();
    },
    getCompetitionEditFile(file, fileList) {
      this.competitionFileName = file.name;
      this.competitionFile = file.raw;
      if (fileList.length > 0) {
        this.isUpload2 = true;
      }
      this.uploadCompetitionImg();
    },
    onCompetitionRemove(file, fileList) {
      console.log(file, fileList);
      this.competitionUrlList = [];
      if (fileList.length > 0) {
        fileList.forEach((item) => {
          this.competitionFileName = item.name;
          this.competitionFile = item.raw;
          this.uploadCompetitionImg();
        });
      }
      if (fileList.length == 0) {
        this.isUpload = false;
      }
    },
    onCompetitionEditRemove(file, fileList) {
      console.log(file, fileList);
      for (let j = 0; j < fileList.length; j++) {
        if (fileList[j].name && fileList[j].raw) {
          this.competitionFileName = fileList[j].name;
          this.competitionFile = fileList[j].raw;
          this.uploadCompetitionImg();
        }
      }

      if (this.editForm.coverUrl == file.url) {
        this.editForm.coverUrl = "";
        this.competitionUrlList = [];
      }
      if (fileList.length == 0) {
        this.isUpload2 = false;
      }
    },
    //新增赛事弹框关闭后的数据清空
    closeDialog() {
      this.form = { discountSetting: [] };
      this.codeFileList = [];
      this.competitionFileList = [];
      console.log(this.form);
    },
    //获取用户信息
    async getUserInfo() {
      try {
        const result = await this.$api.getUserInfo();
        console.log(result);
      } catch (error) {
        console.log(error);
      }
    },
    //是否可见
    async changeStatu(id, isshow) {
      if (isshow) {
        const result = await this.$api.isShow(`id=${id}&action=confirm`);
        if (result.data.status_code == "200") {
          this.$message({
            type: "success",
            message: "修改成功",
          });
          this.getTableData();
        }
      } else if (!isshow) {
        const result = await this.$api.isShow(`id=${id}&action=cancel`);
        if (result.data.status_code == "200") {
          this.$message({
            type: "success",
            message: "修改成功",
          });
          this.getTableData();
        }
      }
    },
    //获取所有赛事信息
    async getTableData() {
      try {
        const result = await this.$api.getData(
          `currentPage=${this.$route.params.currentPage}&pageSize=${this.$route.params.pageSize}&search_LIKE_name=${this.competitionName}&search_EQ_year=${this.year}&search_EQ_isShow=${this.isShow}`
        );
        this.tableData = result.pageResults;
        this.totalCount = result.totalCount;
        // this.status = result.pageResults.status;
        console.log(this.tableData[0].status, "111");
      } catch (error) {
        console.log(error);
      }
    },
    //新增赛事按钮
    handleAddGame() {
      this.dialogFormVisible = true;
    },
    //赛事详情跳转
    handleDetail(index, row) {
      console.log(index, row);
      this.$router.push({
        name: "SportsDetail",
        params: {
          back: row.name,
          id: row.id,
          name: "1",
          menu: "1-1",
          pageSize: 10,
          currentPage: 1,
        },
      });
    },
    //赛事编辑前的数据处理
    handleEdit(index, row) {
      this.dialogEditVisible = true;
      this.editForm = JSON.parse(JSON.stringify(row));
      if (this.editForm.joinQrCode) {
        if (this.codeFileList.length > 0) {
          return;
        }
        this.codeFileList = [];
        let obj1 = new Object();
        obj1.url = this.editForm.joinQrCode;
        this.codeFileList.push(obj1);
      }

      if (this.editForm.coverUrl) {
        if (this.competitionFileList.length > 0) {
          return;
        }
        this.competitionFileList = [];
        let obj2 = new Object();
        obj2.url = this.editForm.coverUrl;
        this.competitionFileList.push(obj2);
      }

      if (this.editForm.discountSetting) {
        if (this.editForm.discountSetting.length > 0) {
          this.editForm.discountSetting = JSON.parse(
            this.editForm.discountSetting
          );
        }
      } else {
        this.editForm.discountSetting = new Array();
      }
      if (this.editForm.discountSetting) {
        if (this.editForm.discountSetting.length > 0) {
          this.editForm.discountSetting.forEach((item) => {
            item.disCountRate = (item.disCountRate / 100).toFixed(2);
            item.startMoney = (item.startMoney / 100).toFixed(2);
            item.endMoney = (item.endMoney / 100).toFixed(2);
          });
        }
      }
    },
    //门票页面跳转
    handleTicket(index, row) {
      console.log(row);
      this.$router.push({
        name: "AdmissionTicket",
        params: {
          back: row.name,
          id: row.id,
          name: "1",
          pageSize: 10,
          currentPage: 1,
        },
      });
    },
    //酒店管理页面跳转
    handleHotel(index, row) {
      this.$router.push({
        name: "Hotel",
        params: {
          back: row.name,
          id: row.id,
          name: "1",
          pageSize: 10,
          currentPage: 1,
          pageSizeTwo: 10,
          currentPageTwo: 1,
        },
      });
    },
    //导出到计分
    async handleExport(index, row,isAllRecord) {
      var a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      try {
        const result = await this.$api.exportToScore(`competitionId=${row.id}&isAllRecord=${isAllRecord}`);
        console.log(result);
        let content = result.headers["content-disposition"].split(";"); // 从响应头中拿到文件名
        let fileName = content[1].split("=")[1]; // 从响应头中拿到文件名
        console.log(decodeURI(fileName), result);
        if (result.data) {
          let blob = new Blob([result.data], {
            type: "application/vnd.ms-excel",
          });
          let objectUrl = URL.createObjectURL(blob); //创建URL
          a.href = objectUrl;
          a.download = decodeURI(fileName);
          a.click();
          URL.revokeObjectURL(objectUrl); // 释放内存
          setTimeout(() => {
            document.body.removeChild(a);
          }, 2000);
        }
      } catch (error) {
        console.log(error);
      }
    },
    //导出订单明细
    async handleExportOrderInfo(index, row) {
      var a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      try {
        const result = await this.$api.exportOrderInfo(`competitionId=${row.id}`);
        console.log(result);
        let content = result.headers["content-disposition"].split(";"); // 从响应头中拿到文件名
        let fileName = content[1].split("=")[1]; // 从响应头中拿到文件名
        console.log(decodeURI(fileName), result);
        if (result.data) {
          let blob = new Blob([result.data], {
            type: "application/vnd.ms-excel",
          });
          let objectUrl = URL.createObjectURL(blob); //创建URL
          a.href = objectUrl;
          a.download = decodeURI(fileName);
          a.click();
          URL.revokeObjectURL(objectUrl); // 释放内存
          setTimeout(() => {
            document.body.removeChild(a);
          }, 2000);
        }
      } catch (error) {
        console.log(error);
      }
    },
    //退款比例页面跳转
    handleRefund(index, row) {
      this.$router.push({
        name: "RefundRatio",
        params: { back: row.name, id: row.id, name: "1" },
      }); // 退款比例
    },
    refundMoney(index, row) {
      this.$router.push({
        name: "RefundMoney",
        params: { back: row.name, id: row.id, name: "1" },
      }); // 退还费用
    },
    //删除赛事信息
    handleDelete(index) {
      this.$confirm("此操作将永久删除该行, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
      }).then(async () => {
        try {
          const result = await this.$api.deleteData(`id=${index}`);
          this.getTableData();
          if (result.data.status_code == "1002") {
            this.$message({
              type: "warning",
              message: result.data.msg,
            });
          } else if (result.data.status_code == "200") {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
          }
        } catch (error) {
          console.log(error);
        }
      });
    },
    //取消赛事
    offRace(index) {
      this.$confirm("此操作将取消赛事, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
      }).then(async () => {
        try {
          const result = await this.$api.cancelCompetition(
            `competitionId=${index}`
          );
          this.getTableData();
          if (result.data.status_code == "1002") {
            this.$message({
              type: "warning",
              message: result.data.msg,
            });
          } else if (result.data.status_code == "200") {
            this.$message({
              type: "success",
              message: "取消成功!",
            });
          }
        } catch (error) {
          console.log(error);
        }
      });
    },
    //清理未支付报名
    clearApply(index) {
      this.$confirm("此操作将清理未支付报名, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
      }).then(async () => {
        try {
          const result = await this.$api.clearUnPayApplyList(
            `competitionId=${index}`
          );
          this.getTableData();
          if (result.data.status_code == "1002") {
            this.$message({
              type: "warning",
              message: result.data.msg,
            });
          } else if (result.data.status_code == "200") {
            this.$message({
              type: "success",
              message: "清理成功!",
            });
          }
        } catch (error) {
          console.log(error);
        }
      });
    },
    handleSizeChange(val) {
      this.$router.replace({
        name: "SportsManagement",
        params: {
          pageSize: val,
          currentPage: +this.$route.params.currentPage,
        },
      });
      this.getTableData();
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      this.$router.replace({
        name: "SportsManagement",
        params: {
          pageSize: +this.$route.params.pageSize,
          currentPage: val,
        },
      });
      this.getTableData();
      console.log(`当前页: ${val}`);
    },
    //提交新增赛事信息
    pushGame() {
      if (!this.isUpload) {
        this.$message({
          type: "warning",
          message: "请上传赛事封面图",
        });
        return;
      }
      this.$refs.formAdd.validate(async (valid) => {
        console.log(valid);
        if (valid) {
          this.dialogFormVisible = false;
          if (this.form.discountSetting.length > 0) {
            this.form.discountSetting.forEach((item) => {
              item.disCountRate *= 100;
              item.startMoney *= 100;
              item.endMoney *= 100;
            });
            this.form.discountSetting = JSON.stringify(
              this.form.discountSetting
            );
          }

          this.codeUrlList.forEach((item) => {
            this.joinQrCode = this.codePath + item;
          });

          this.form.joinQrCode = this.joinQrCode;

          this.competitionUrlList.forEach((item) => {
            this.coverUrl = this.competitionPath + item;
          });

          this.form.coverUrl = this.coverUrl;

          console.log(this.form);
          try {
            const result = await this.$api.addGame(this.form);
            if (result.data.status_code == "1002") {
              this.$message({
                message: result.data.msg,
                type: "warning",
              });
            } else if (result.data.status_code == "200") {
              this.$message({
                message: "添加成功",
                type: "success",
              });
              this.getTableData();
            }
          } catch (error) {
            console.log(error);
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //提交赛事编辑信息
    pushEdit() {
      if (!this.isUpload2) {
        this.$message({
          type: "warning",
          message: "请上传赛事封面图",
        });
        return;
      }
      this.$refs.formEdit.validate(async (valid) => {
        if (valid) {
          this.codeUrlList.forEach((item) => {
            this.editForm.joinQrCode = this.codePath + item;
          });

          this.competitionUrlList.forEach((item) => {
            this.editForm.coverUrl = this.competitionPath + item;
          });
          this.editFormPush = JSON.parse(JSON.stringify(this.editForm));
          if (this.editFormPush.discountSetting) {
            if (this.editFormPush.discountSetting.length > 0) {
              this.editFormPush.discountSetting.forEach((item) => {
                item.startMoney *= 100;
                item.endMoney *= 100;
                item.disCountRate *= 100;
              });
              this.editFormPush.discountSetting = JSON.stringify(
                this.editFormPush.discountSetting
              );
            }
          }
          try {
            const result = await this.$api.edit(this.editFormPush);
            console.log(result);
            if (result.data.status_code == "1002") {
              this.$message({
                message: result.data.msg,
                type: "warning",
              });
            } else if (result.data.status_code == "200") {
              this.dialogEditVisible = false;
              this.$message({
                message: "修改成功",
                type: "success",
              });
              this.getTableData();
            }
          } catch (error) {
            console.log(error);
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //添加折扣(新增)
    addDomain() {
      this.form.discountSetting.push({
        startMoney: "",
        endMoney: "",
        disCountRate: "",
      });
    },
    //添加折扣(编辑)
    addDomainEdit() {
      this.editForm.discountSetting.push({
        startMoney: "",
        endMoney: "",
        disCountRate: "",
      });
    },
    //删除折扣(新增)
    removeDomain(item) {
      var index = this.form.discountSetting.indexOf(item);
      if (index !== -1) {
        this.form.discountSetting.splice(index, 1);
      }
    },
    //删除折扣(编辑)
    removeDomainEdit(item) {
      var index = this.editForm.discountSetting.indexOf(item);
      if (index !== -1) {
        this.editForm.discountSetting.splice(index, 1);
      }
    },
  },
};
</script>

<style lang="less">
@font-face {
  font-family: "iconfont"; /* Project id 2817395 */
  src: url("//at.alicdn.com/t/font_2817395_n3eegmhdpfs.woff2?t=1631849645635")
      format("woff2"),
    url("//at.alicdn.com/t/font_2817395_n3eegmhdpfs.woff?t=1631849645635")
      format("woff"),
    url("//at.alicdn.com/t/font_2817395_n3eegmhdpfs.ttf?t=1631849645635")
      format("truetype");
}
.SportsManagement {
  .iconfont {
    font-family: "iconfont" !important;
    font-size: 16px;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    &:hover {
      cursor: pointer;
    }
  }
  .iconfont-competition {
    font-size: 48px;
  }
  .box-card {
    width: 100%;
    .clearfix {
      &:before,
      &:after {
        display: table;
        content: "";
      }
      &:after {
        clear: both;
      }
      .btn {
        display: flex;
        // float: right;
        .select {
          width: 100px;
          margin-right: 20px;
        }
        .whether {
          display: inline-block;
          height: 40px;
          line-height: 40px;
        }
      }
    }
    .main-card {
      /* switch按钮样式 */
      .switch .el-switch__label {
        position: absolute;
        display: none;
        color: #fff !important;
        top: 0;
        left: 10px;
      }
      /*打开时文字位置设置*/
      .switch .el-switch__label--right {
        z-index: 1;
      }
      /* 调整打开时文字的显示位子 */
      .switch .el-switch__label--right span {
        margin-right: 27px;
      }
      /*关闭时文字位置设置*/
      .switch .el-switch__label--left {
        z-index: 1;
      }
      /* 调整关闭时文字的显示位子 */
      .switch .el-switch__label--left span {
        margin-left: 1px;
      }
      /*显示文字*/
      .switch .el-switch__label.is-active {
        display: block;
      }
      /* 调整按钮的宽度 */
      .switch.el-switch .el-switch__core,
      .el-switch .el-switch__label {
        width: 50px !important;
        margin: 0;
      }
    }
    .page-card {
      margin: 30px 0;
      float: right;
    }

    .text {
      font-size: 14px;
    }

    .item {
      margin-bottom: 18px;
    }
  }
  .dialog-footer,
  .el-dialog__header {
    text-align: center;
  }
  .competition {
    .el-dialog {
      min-height: 1200px;
      min-width: 960px;
    }
  }
  .el-col-11 {
    width: 20%;
  }
  .el-col-2 {
    width: 1.3%;
  }
  .el-dropdown-link {
    cursor: pointer;
    color: #409eff;
  }
  .el-icon-arrow-down {
    font-size: 12px;
  }
}
</style>
<style>
.el-dropdown-menu__item {
  text-align: center !important;
}
</style>
